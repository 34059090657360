import "./newsletter-signup.scss";

import { SyntheticEvent, useState, ReactNode } from "react";
import { IconArrow } from "design-system/components/icons/icon-arrow";
import { IconCheckLarge } from "design-system/components/icons/icon-check-large";

export interface NewsletterSignupProps {
  title?: ReactNode;
  subtitle?: ReactNode;
  placeholder?: string;
  successTitle?: ReactNode;
  successSubtitle?: ReactNode;
}

/**
 * Simple form example
 *
 * - **`id: P-013-000-00`**
 */
export function NewsletterSignup({
  title,
  subtitle,
  placeholder = "you@example.com",
  successTitle = "Thank you for signing up.",
  successSubtitle = "Watch your inbox for the latest insights from HBS.",
}: NewsletterSignupProps) {
  const [showSuccess, setShowSuccess] = useState(false);

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();

    setShowSuccess(true);
  };

  return showSuccess ? (
    <div className="hbs-newsletter-signup hbs-newsletter-signup--success">
      <div className="hbs-newsletter-signup__success-container">
        <span className="hbs-newsletter-signup__success-indicator">
          <IconCheckLarge className="hbs-newsletter-signup__icon hbs-newsletter-signup__icon--success" />
        </span>
        <span className="hbs-newsletter-signup__success-message">
          {successTitle && (
            <p className="hbs-newsletter-signup__title">{successTitle}</p>
          )}
          {successSubtitle && (
            <p className="hbs-newsletter-signup__subtitle">{successSubtitle}</p>
          )}
        </span>
      </div>
    </div>
  ) : (
    <form
      className="hbs-form hbs-newsletter-signup"
      onSubmit={(e) => onSubmit(e)}
    >
      {title && <p className="hbs-newsletter-signup__title">{title}</p>}
      {subtitle && (
        <p className="hbs-newsletter-signup__subtitle">{subtitle}</p>
      )}
      <fieldset>
        <div className="hbs-form__row hbs-newsletter-signup__input-container">
          <input
            className="hbs-newsletter-signup__input"
            type="email"
            placeholder={placeholder}
            id="email"
          />
          <button className="hbs-newsletter-signup__submit" type="submit">
            <IconArrow className="hbs-newsletter-signup__icon" />
          </button>
        </div>
      </fieldset>
    </form>
  );
}
