import "./cta-card.scss";

import cn from "clsx";
import { CtaLink } from "design-system/components/primitives/cta-link/cta-link";
import { CtaList } from "design-system/components/primitives/cta-list/cta-list";
import { MediaAsset } from "design-system/components/primitives/media-asset/media-asset";
import { NewsletterSignup } from "design-system/components/primitives/newsletter-signup/newsletter-signup";
import { EditAttributes } from "design-system/types/types";
import { ReactNode } from "react";
import { CtaProps, CtaPropsEditAttributes } from "../cta/cta";

export interface CtaCardProps extends CtaProps {
  overline?: ReactNode;
  hasEmailCapture?: boolean;
  removeImageGap?: boolean;
  emailCapturePlaceholder?: string;
  emailCaptureSuccessTitle?: ReactNode;
  emailCaptureSuccessSubtitle?: ReactNode;
  HeadingLevel?: "h2" | "h3";
  editAttributes?: CtaPropsEditAttributes & {
    overline?: EditAttributes;
  };
}

/**
 *
 *   Small Text Style with CTA list and 3 images
 *
 * ## See it in use on...
 * - The [kitchen sink article page](/story/example-pages-article-pages--kitchen-sink-article)
 *
 * - **`id: CB-002-001-01`**
 * - **`data-region: cb__cta__small__linklist`**
 */
export function CtaCard({
  align,
  overline,
  title,
  subtitle,
  ctaList,
  primaryCta,
  secondaryCta,
  hasEmailCapture = false,
  removeImageGap = false,
  mediaAlignment = "right",
  emailCapturePlaceholder,
  emailCaptureSuccessTitle,
  emailCaptureSuccessSubtitle,
  media,
  theme = "crimson",
  editAttributes,
  HeadingLevel = "h2",
}: CtaCardProps) {
  const hasButton = Boolean((!ctaList && primaryCta) || secondaryCta);
  const hasArt = media && media.length > 0 && align !== "center";
  const alignWidthDefaults = align ? align : hasArt ? "full" : "center";
  const wrapperClass = cn(
    "hbs-component--cta-card",
    `hbs-global-align-${alignWidthDefaults}`,
  );
  const TitleTag = overline ? "div" : HeadingLevel;

  return (
    <div
      {...editAttributes?.block}
      className={wrapperClass}
      data-region={`cb__cta__small__${ctaList ? "linklist" : "button"}`}
      data-theme={
        (removeImageGap || hasEmailCapture || align === "wide") && theme
      }
    >
      <div
        className={cn(
          "hbs-cta-card",
          hasArt && "hbs-cta-card--has-art",
          (removeImageGap || hasEmailCapture || align === "wide") &&
            "hbs-cta-card--no-image-gap",
          hasArt &&
            media.length > 1 &&
            `hbs-cta-card--art-count-${media.length}`,
          mediaAlignment === "left"
            ? "hbs-cta-card--media-left"
            : "hbs-cta-card--media-right",
        )}
      >
        <div className="hbs-cta-card__content">
          <div className="hbs-cta-card__inner" data-theme={theme}>
            {overline && (
              <h2
                {...editAttributes?.overline}
                className="hbs-cta-card__overline"
              >
                {overline}
              </h2>
            )}

            <TitleTag
              {...editAttributes?.title}
              className="hbs-cta-card__title"
            >
              {title}
            </TitleTag>

            {subtitle && (
              <div
                {...editAttributes?.subtitle}
                className="hbs-cta-card__subtitle"
              >
                {subtitle}
              </div>
            )}

            {hasEmailCapture && (
              <NewsletterSignup
                placeholder={emailCapturePlaceholder}
                successTitle={emailCaptureSuccessTitle}
                successSubtitle={emailCaptureSuccessSubtitle}
              />
            )}

            {hasButton && (
              <div className="hbs-cta-card__cta">
                {primaryCta && (
                  <CtaLink {...primaryCta}>{primaryCta.children}</CtaLink>
                )}
                {secondaryCta && (
                  <CtaLink {...secondaryCta}>{secondaryCta.children}</CtaLink>
                )}
              </div>
            )}
            {ctaList && (
              <div className="hbs-cta-card__cta">
                <CtaList {...ctaList} />
              </div>
            )}
          </div>
        </div>

        {hasArt && (
          <div className="hbs-cta-card__art">
            {media.map((asset, i) => (
              <MediaAsset
                key={i}
                {...asset}
                aspectRatio={media.length === 1 ? "3/2" : "3/4"}
                className="hbs-cta-card__asset"
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
